body {
  height: 100%;
  margin: 20px;
  padding: 20px;
  min-width: 1024px;	
  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  padding: 20px;
}

.fc .fc-timegrid-slot {
  height: 9.0em !important; 
  border-bottom: 0 !important/* each cell owns its top border */;
}

#event {
  overflow-y: scroll; /* прокрутка по вертикали */
  overflow-x: hidden; /* скрываем прокрутку по горизонтали */
  height: 100%;
  width: 100%;
  word-wrap: break-word; /* перенос текста в div блоке*/
  background-color: rgb(92, 92, 96);
}

#modal1 {
  height: 60%;
  width: 50%;
  border-radius: 2px;
}

#modal2 {
  height: 60%;
  width: 50%;
}

.flex-box {
  display: flex; 
  justify-content: space-between;  
  padding: 0px;
}

.modal { 
  max-height: 100% !important ; 
  overflow-y: hidden !important ;
}  

.modal-header {
  border-bottom: 1px solid rgba(0,0,0,0.1);
  width: 100%; 
  height: auto;
  padding: 20px;
}

a {
  color: rgb(86, 84, 84);
  text-decoration: none;
}

.fc table {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1em;
  color: rgb(86, 84, 84);
}

.d-flex {
  display: flex!important;
  color: rgb(86, 84, 84);
  font-weight: normal;
  font-size: 1em;
}

.list-group{
  max-height: 90px;
  margin-bottom: 10px;
  overflow-y:auto;
  -webkit-overflow-scrolling: touch;
}

#Edit6{
  max-height: 36px;
  /*margin-bottom: 10px;*/
  overflow-y:auto;
  -webkit-overflow-scrolling: touch;
}

#button_select {
  color: black;
  max-height: 20px; 
  font-size: 10px; 
  text-decoration: none;
  bottom: 3px;
}

#button_select_place {
  color: black;
  max-height: 20px; 
  font-size: 10px; 
  text-decoration: none;
  bottom: 3px;
}

#button_select:hover {
  color: red;
}

.input-group-text{
  background-color: #fff;
}

.dropdown-menu {
  max-height: 80px;
  overflow-y: scroll;
  position: relative; /* важно */
  z-index: 1;
  /*inset: 0px 0px auto 0px !important;
  transform: translate3d(0px, 40px, 0px) !important;*/
}


.dropdown-item {
  width: 396px;
}

.dropdown-toggle {
  background-color: #fff !important;
  color: black;
  border-color: #ced4da !important;
}

.dropdown-toggle:hover {
  background-color: #fff !important;
  color: rgb(167, 151, 151);
}

.dropdown-toggle:active {
  background-color: #fff !important;
  color: black;
  color: rgb(167, 151, 151);
}

.dropdown-toggle:focus {
  background-color: #fff !important;
  color: black;
  color: rgb(167, 151, 151);
}



#dropdown-button1 {
  text-decoration: none;
}